import React from 'react';

import {
  Box,
  Button,
  Container,
  Hidden,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';

import useSWR from 'swr';

import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

import InfoCircle from './InfoCircle';


const HeaderBackground = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  marginTop: '-80px',
  paddingTop: '120px',
  paddingBottom: '40px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundImage: 'url("/img/journey-xs.jpg")',
  boxShadow: 'inset 0 0 0 1000px rgba(19, 53, 70, 0.69)',
  [theme.breakpoints.only('sm')]: {
    backgroundImage: 'url("/img/journey.jpg")',
  },
  [theme.breakpoints.up('md')]: {
    marginTop: '-120px',
    paddingTop: '260px',
    paddingBottom: '120px',
    backgroundImage: 'url("/img/journey.jpg")',
  }
}));


const HomeHero = ({ intialCounters }) => {
  const { data: counters } = useSWR('/content/counters/', null, { fallbackData: intialCounters });

  return (
    <HeaderBackground>
      <Container maxWidth="md">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          color="white"
          sx={{
            alignItems: {
              xs: 'center',
              md: 'flex-start',
            },
            textAlign: {
              xs: 'center',
              md: 'left',
            }
          }}
        >
          <Typography variant="h3" component="h1" gutterBottom>
            {/* Make the words dynamic and animated */}
            Get back on <Hidden smUp><br /></Hidden> <Box component="span" color="secondary.main">your journey</Box>
          </Typography>
          <Typography variant="h5" component="h2">
            The easiest way to get cost estimates from<br />
            the best mechanics near you
          </Typography>
          <Box
            display="flex"
            justifyContent="flex-start"
            mt={4}
          >
            <InfoCircle
              value={counters.job_count}
              title={<>Quote<br />Requests</>}
              bgcolor="none"
              borderColor="white"
            />

            <InfoCircle
              value={counters.estimate_count}
              title={<>Estimates<br />Received</>}
              bgcolor="none"
              borderColor="white"
            />

            <InfoCircle
              value={counters.review_count}
              title={<>User<br />Reviews</>}
              bgcolor="none"
              borderColor="white"
            />
          </Box>

          {/* Add get started functionality for desktop */}
          <Box mt={2} display={{xs: "block", md: "none"}}>
            <Button
              color="secondary"
              variant="contained"
              endIcon={<DirectionsCarIcon />}
              href="#start"
            >
              Let&apos;s Get Started
            </Button>
          </Box>
        </Box>
      </Container>
    </HeaderBackground>
  )
}

export default HomeHero;
