import {
  Box,
  Button,
  Container,
  Hidden,
  Typography
} from '@mui/material';

import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';


const StartSection = () => {
  return (
    <Hidden mdDown implementation="css">
      <Box width="100%" bgcolor="primary.dark" py={4}>
        <Container maxWidth="md">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            color="white"
          >
            <Typography variant="h6" component="h3" gutterBottom>
              Get started and request quotes now
            </Typography>

            <Button
              color="secondary"
              variant="contained"
              endIcon={<DirectionsCarIcon />}
              href="#start"
              size="large"
            >
              Let&apos;s Get Started
            </Button>
          </Box>
        </Container>
      </Box>
    </Hidden>
  );
}

export default StartSection;
