import Image from 'next/image';

import {
  Box,
  Button,
  Typography
} from '@mui/material';


const WorkshopSection = () => {

  return (
    <Box
      sx={{
        py: 8,
        display: "flex",
        alignItems: "center",
        bgcolor: 'primary.main',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: 'url("/img/engine-x2.jpg")',
      }}
    >
      <Box
        display={{xs: "none", md: "block"}}
        sx={{
          minWidth: 380,
          position: "relative"
        }}
      >
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            left: "-28px",
            top: "-166px"
          }}
        >
          <Image
            src="/img/ferrari-x2.png"
            alt="Orange Ferrari"
            width={380}
            height={248}
          />
        </Box>
      </Box>

      <Box
        sx={{
          textAlign: { xs: "center", sm: 'left' },
          pr: { xs: 2, sm: 0 },
          pl: { xs: 2, sm: 4 },
          color: "white"
        }}
      >
        <Typography variant="h4" gutterBottom>
          Are you a mechanic or workshop owner?
        </Typography>
        <Typography variant="body1">
          We deliver potential customers right to your inbox. Increase your online presence and connect with new customers. This process only takes a few minutes
        </Typography>

        <Box px={4} pt={4} whiteSpace="nowrap" display={{xs: "block", sm: "none"}}>
          <Button
            color="secondary"
            variant="outlined"
            sx={{
              border: "1px solid white",
              color: 'white'
            }}
            href="/for-mechanics/"
          >Register Today</Button>
        </Box>
      </Box>

      <Box px={4} whiteSpace="nowrap" display={{xs: "none", sm: "block"}}>
        <Button
          color="secondary"
          variant="outlined"
          sx={{
            border: "1px solid white",
            color: 'white'
          }}
          href="/for-mechanics/"
        >Register Today</Button>
      </Box>
    </Box>
  )
}

export default WorkshopSection;
