const website = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  "url": "https://www.mechanicbuddy.co.za/",
  "name": "MechanicBuddy",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "https://www.mechanicbuddy.co.za/search-results/?q={search_term_string}",
    "query-input": "required name=search_term_string"
  }
}

const Website = () => {
  return (
    <script
      key="website"
      type='application/ld+json'
      dangerouslySetInnerHTML={{ __html: JSON.stringify(website)}}
    />
  )
}

export default Website
