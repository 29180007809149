import React from 'react';

import Image from 'next/image';

import { useTheme } from '@mui/material/styles';

import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  Typography,
  useMediaQuery
} from '@mui/material';

import SearchEstimates from '../components/SearchEstimates';


const EstimateSection = () => {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const big = useMediaQuery(theme.breakpoints.up('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      id="start"
      sx={{
        bgcolor: "white",
        pt: {
          xs: 0,
          md: 8
        },
        pb: {
          xs: 4,
          md: 8
        }
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          // spacing={4}
          alignItems="center"
        >
          <Grid item xs={12} md={7}>
            <Box
              fontSize="h2.fontSize"
              sx={{
                display: {
                  xs: 'block',
                  md: 'none'
                },
                bgcolor: "white",
                width: "100vw",
                ml: {
                  xs: -2,
                  sm: -3,
                },
                pl: {
                  xs: 2,
                  sm: 3,
                },
                pt: 10,
                pb: 6,
                color: "white",
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundImage: 'url("/img/dog-in-car-x2.jpg")',  // Add smaller image for mobile
                textAlign: "center"
              }}
            >
              Get back to enjoying your ride
            </Box>
            <Box
              fontSize="h2.fontSize"
              sx={{
                display: {
                  xs: 'none',
                  md: 'block'
                },
                bgcolor: "white",
              }}
            >
              Get back to enjoying your ride
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={6}
            >
              <Box 
                sx={{
                  pr: {
                    xs: 0.5,
                    md: 8,
                  },
                  flexGrow: 1
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    minHeight: {
                      xs: '56px',
                      sm: null,
                    }
                  }}
                >Quotes from mechanics</Typography>
                <Box mt={2} mb={4}>Post your job and receive responses from mechanics near you.</Box>
                <Button
                  href="/request-quotes/"
                  color="primary"
                  variant="contained"
                  size={big ? "large" : "medium"}
                >
                  Get Quotes
                </Button>
              </Box>

              <Box
                sx={{
                  pr: {
                    xs: 0.5,
                    md: 8,
                  },
                  flexGrow: 1
                }}>
                <Typography
                  variant="h6"
                  sx={{
                    minHeight: {
                      xs: '56px',
                      sm: null,
                    }
                  }}
                >Quick cost estimate</Typography>
                <Box mt={2} mb={4}>Get an instant cost estimate based on past service and repair quotes.</Box>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => handleClickOpen()}
                  size={big ? "large" : "medium"}
                >
                  Get Estimate
                </Button>
              </Box>
            </Box>
          </Grid>
          <Hidden mdDown>
            <Grid item xs={12} md={5}>
              <Image
                src="/img/dog-in-car-x2.jpg"
                alt="Dog enjoying car"
                width={512}
                height={566}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
      <SearchEstimates data={{}} open={open} handleClose={handleClose} />
    </Box>
  );
}

export default EstimateSection;
