const orgData = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "url": "https://www.mechanicbuddy.co.za/",
  "name": "MechanicBuddy",
  "logo": "https://www.mechanicbuddy.co.za/logos/mechanicbuddy-logo.jpg",
  "sameAs": "https://www.facebook.com/mechanicbuddyZA"
}

const Organization = () => {
  return (
    <script
      key="organization"
      type='application/ld+json'
      dangerouslySetInnerHTML={{ __html: JSON.stringify(orgData)}}
    />
  )
}

export default Organization
