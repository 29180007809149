import React from 'react';

import Image from 'next/image';

import { useTheme } from '@mui/material/styles';

import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  useMediaQuery,
} from '@mui/material';

import useSWR from 'swr';

import RecentReviews from './RecentReviews';
import { NextLinkComposed } from './Link';


const MechanicSection = () => {
  const theme = useTheme();

  const big = useMediaQuery(theme.breakpoints.up('sm'));

  const { data, error } = useSWR('/content/recent-reviews/');

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Grid
        container
        spacing={4}
        alignItems="center"
        sx={{
          mb: 3
        }}
      >
        <Hidden mdDown>
          <Grid item xs={12} md={7}>
            <Box pr={4}>
              <Image
                src="/img/woman-in-car-x2.jpg"
                alt="Enjoying a classic car"
                width={674}
                height={512}
              />
            </Box>
          </Grid>
        </Hidden>

        <Grid item xs={12} md={5}>
          <Box
            fontSize="h2.fontSize"
            sx={{
              textAlign: {
                xs: "center",
                lg: 'left',
              }
            }}
          >
            Find the best mechanics near you
          </Box>

          <Box
            sx={{
              mt: 6,
              mb: 4,
              textAlign: {
                xs: "center",
                lg: 'left',
              }
            }}
          >
            See what others had to say about their experiences, compare reviews and recommendations of mechanics and workshops near you.
          </Box>

          <Box
            sx={{
              display: {
                xs: "flex",
                md: "block",
              },
              justifyContent: "center",
            }}>
            <Button
              color="primary"
              variant="contained"
              sx={{ mr: 2 }}
              size={big ? "large" : "medium"}
              component={NextLinkComposed}
              to={{ pathname: '/best-mechanics/' }}
            >
              Find a Mechanic
            </Button>
            <Button
              color="secondary"
              variant="contained"
              component={NextLinkComposed}
              to={{ pathname: '/write-review/' }}
              size={big ? "large" : "medium"}
            >
              Write a review
            </Button>
          </Box>
        </Grid>
      </Grid>
      {data && data.results && <RecentReviews reviews={data.results} />}
    </Container>
  );
}

export default MechanicSection;
