import {
  Box
} from '@mui/material';


const InfoCircle = ({value, title, bgcolor = "white", borderColor = "primary.light"}) => {

  return (
    <Box
      sx={{
        my: 1,
        bgcolor: bgcolor,
        border: 2,
        borderColor: borderColor,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        width: {
          xs: '90px',
          sm: '120px',
          lg: '140px'
        },
        height: {
          xs: '90px',
          sm: '120px',
          lg: '140px'
        },
        ml: {
          xs: 1,
          sm: 2,
        },
        mr: {
          xs: 1,
          sm: 2,
        }
      }}
    >
      <Box
        sx={{
          color: 'secondary.main',
          fontSize: {
            xs: 'h5.fontSize',
            md: 'h4.fontSize',
          }
        }}
      >{value}</Box>
      <Box fontSize="12px" lineHeight="15px" textAlign="center">{title}</Box>
    </Box>
  )
}

export default InfoCircle;
