const cars = [
  "Alfa Romeo",
  "Aston Martin",
  "Audi",
  "Bentley",
  "BMW",
  "Cadillac",
  "Chery",
  "Chevrolet",
  "Chrysler",
  "Citroen",
  "Daewoo",
  "Daihatsu",
  "Dodge",
  "FAW",
  "Ferrari",
  "Fiat",
  "Ford",
  "Foton",
  "Geely",
  "GWM",
  "Honda",
  "Hummer",
  "Hyundai",
  "Infiniti",
  "Isuzu",
  "Iveco",
  "Jaguar",
  "Jeep",
  "Jinbei",
  "Jmc",
  "Kia",
  "Lamborghini",
  "Land Rover",
  "Lexus",
  "Lotus",
  "Mahindra",
  "Maserati",
  "Mazda",
  "Mclaren",
  "Mercedes",
  "MG",
  "Mini",
  "Mitsubishi",
  "Nissan",
  "Opel",
  "Peugeot",
  "Porsche",
  "Proton",
  "Renault",
  "Rolls-Royce",
  "Rover",
  "Saab",
  "Seat",
  "Smart",
  "Soyat",
  "SsangYong",
  "Subaru",
  "Suzuki",
  "Tata",
  "Toyota",
  "Volkswagen",
  "Volvo",
  "Zotye"
];

export default cars;
