import React from 'react';

import Head from 'next/head';

import axios from 'axios';

import AsSeenIn from '../components/AsSeenIn';
import TopNav from '../components/TopNav';
import HomeHero from '../components/HomeHero';
import HowItWorks from '../components/HowItWorks';
import EstimateSection from '../components/EstimateSection';
import MechanicSection from '../components/MechanicSection';
import BottomNav from '../components/BottomNav';
import WorkshopSection from '../components/WorkshopSection';
import StartSection from '../components/StartSection';
import Website from '../components/structured-data/Website';
import Organization from '../components/structured-data/Organization';


const Index = ({ counters }) => {
  return (
    <>
      <Head>
        <title>MechanicBuddy - Service & Repair Cost Estimates made Easy</title>
        <meta name="description" content="The easy way to get service and repair cost estimates for your car. Check out reviews of the best local mechanics and workshops near you." />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.mechanicbuddy.co.za" />
        <meta property="og:title" content="Get Back to Enjoying Your Car" />
        <meta property="og:description" content="The easy way to get service and repair cost estimates for your car. Check out reviews of the best local mechanics and workshops near you." />
        <meta property="og:image" content="https://www.mechanicbuddy.co.za/logos/mechanicbuddy-text-logo-square.jpg" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content="https://www.mechanicbuddy.co.za" />
        <meta property="twitter:title" content="Get Back to Enjoying Your Car" />
        <meta property="twitter:description" content="The easy way to get service and repair cost estimates for your car. Check out reviews of the best local mechanics and workshops near you." />
        <meta property="twitter:image" content="https://www.mechanicbuddy.co.za/logos/mechanicbuddy-text-logo-square.jpg" />

        <link rel="canonical" href="https://www.mechanicbuddy.co.za" />
        <>
        <Organization />
        <Website />

        <link rel="preload" as="image" href="/img/journey-xs.jpg" />
        </>
      </Head>

      <TopNav color="transparent" />

      <HomeHero intialCounters={counters} />

      <StartSection />

      <HowItWorks />

      <EstimateSection />

      <MechanicSection />

      <AsSeenIn />

      <WorkshopSection />

      <BottomNav />
    </>
  );
}

export async function getStaticProps() {
  const result = await axios(`${process.env.DOMAIN}/content/counters/`);

  return {
    props: { counters: result.data }
  }
}

export default Index;
