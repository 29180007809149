import React from 'react';

import Image from 'next/image';

import {
  Box,
  Container,
  Typography,
  Grid
} from '@mui/material';


const HowItWorks = () => {

  return (
    <Container fixed sx={{ p: theme => theme.spacing(8, 0) }}>
      <Box textAlign="center" mb={6}>
        <Typography variant="h3">How It Works</Typography>
      </Box>

      <Grid
        container
        justifyContent="space-between"
      >
        <Grid item xs={6} md={3}>
          <Box textAlign="center" p={2}>
            <Image
              src="/icons/submit-a-request.svg"
              width={200}
              height={200}
              alt="Request Quotes"
              title="Request Quotes"
            />
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>Submit a request</Typography>
            <Box>Tell us what your car needs and how to contact you</Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box textAlign="center" p={2}>
            <Image
              src="/icons/receive-quotes.svg"
              width={200}
              height={200}
              alt="Negotiate"
              title="Negotiate and compare"
              loading="lazy"
            />
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>Receive quotes</Typography>
            <Box>Compare cost estimates and reviews of mechanics near you</Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box textAlign="center" p={2}>
            <Image
              src="/icons/choose-a-mechanic.svg"
              width={200}
              height={200}
              alt="Mechanic"
              title="Choose a mechanic"
              loading="lazy"
            />
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>Choose a mechanic</Typography>
            <Box>Chat with mechanics and book an appointment</Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box textAlign="center" p={2}>
            <Image
              src="/icons/give-feedback.svg"
              width={200}
              height={200}
              alt="Feedback"
              title="Feedback"
              loading="lazy"
            />
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>Give Feedback</Typography>
            <Box>Rate the service you received and help others</Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default HowItWorks;
