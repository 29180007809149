import React from 'react';

import Image from 'next/image';

import {
  Box,
  Grid,
  Link,
  Typography,
} from '@mui/material';


const AsSeenIn = () => {
  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5">As Seen In</Typography>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        sx={{
          p: 2
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Link
            href="https://www.wisemove.co.za/moving-tip/mobile-mechanic-near-me"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <Image 
              src="/logos/wise-move-logo.png"
              alt="Wise Move"
              width={360}
              height={54}
            />
          </Link>
        </Grid>

        <Grid 
          item
          xs={12}
          sm={6}
          md={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Link
            href="https://www.iol.co.za/motoring/industry-news/find-your-nearest-mechanic-buddy-1890231"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <Image 
              src="/logos/iol-logo.svg"
              alt="Wise Move"
              width={183}
              height={72}
            />
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AsSeenIn;
